@font-face {
font-family: 'generalSansFont';
src: url(/_next/static/media/c44738e2d67c7de3-s.p.ttf) format('truetype');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: 'generalSansFont';
src: url(/_next/static/media/1002a070cd2963ed-s.p.ttf) format('truetype');
font-display: swap;
font-style: italic;
}@font-face {font-family: 'generalSansFont Fallback';src: local("Arial");ascent-override: 91.05%;descent-override: 21.64%;line-gap-override: 9.01%;size-adjust: 110.93%
}.__className_17a936 {font-family: 'generalSansFont', 'generalSansFont Fallback'
}.__variable_17a936 {--general-sans-font: 'generalSansFont', 'generalSansFont Fallback'
}

